import { graphql } from 'gatsby';
import React from 'react';
import SEO from '../components/SEO';
import CategoriesNav from '../components/Category/CategoryNav';
import { ListThumb } from '../components/Post/PostList';
import Container from '../components/Layout/Container';

export default function Home({ data }) {
  const recipes = data.recipes.nodes;
  const latest = recipes.slice(0, 10);

  return (
    <section>
      <SEO />
      <Container>
        <CategoriesNav />
        <h2>Recién salidas del horno</h2>
        <ListThumb recipes={latest} />
      </Container>
    </section>
  );
}

export const query = graphql`
  query {
    recipes: allRecipesJson(sort: { fields: created_at, order: DESC }) {
      nodes {
        slug
        description
        title
        time
        people
        price
        created_at
        categories
        images {
          name
          type
          order
          title
          description
        }
      }
    }
    categories: allCategoriesJson {
      nodes {
        name
        slug
      }
    }
  }
`;
