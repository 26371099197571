import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

const StyledCategoriesNav = styled.section`
  margin: 1.5rem 0;
  text-align: center;

  nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  h2 {
    font-size: 2.0rem;
    color: var(--color-primary);
  }

  a {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    margin: 5px;
    color: var(--color-label-fg);
    background: var(--color-label-bg);
    border-radius: 2px;
    text-decoration: none;
    font-size: 1.5rem;

    span:first-child {
      margin-right: 0.5rem;
    }

    .count {
      padding: 2px 5px;
    }

    &[aria-current='page'],
    &.active {
      color: var(--color-font);
      background: var(--color-accent);
    }

    &:hover {
      color: var(--color-font);
      text-decoration: none;
      background: var(--color-accent);
    }
  }
`;

function countRecipesInCategories(recipes, categories) {
  const counts = recipes
    .map((recipe) => recipe.categories)
    .flat()
    .reduce((acc, categorySlug) => {
      const existingCategory = acc[categorySlug];

      if (existingCategory) {
        existingCategory.count += 1;
        return acc;
      }

      const category = categories.find((item) => item.slug === categorySlug);

      acc[categorySlug] = {
        slug: category.slug,
        name: category.name,
        count: 1,
      };

      return acc;
    }, {});

  const sortedCategories = Object.values(counts).sort(
    (a, b) => b.count - a.count,
  );

  return sortedCategories;
}

export default function CategoryNav({ activeFilter }) {
  const { recipes, categories } = useStaticQuery(graphql`
    query {
      recipes: allRecipesJson {
        nodes {
          slug
          categories
        }
      }
      categories: allCategoriesJson {
        nodes {
          slug
          name
        }
      }
    }
  `);

  const categoriesCount = countRecipesInCategories(
    recipes.nodes,
    categories.nodes,
  );

  return (
    <StyledCategoriesNav>
      <h2>¿Qué te apetece cocinar hoy?</h2>
      <nav id="categories">
        {categoriesCount.map((category) => (
          <Link
            to={`/recetas/${category.slug}`}
            key={category.slug}
            className={category.slug === activeFilter ? 'active' : ''}
          >
            <span className="name">{category.name}</span>
            <span className="count">{category.count}</span>
          </Link>
        ))}
      </nav>
    </StyledCategoriesNav>
  );
}
